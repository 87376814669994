/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.card {
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-bottom: 10px; /* Added */
  }

  .card-1 {
    padding: 10px 10px 10px 10px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  .la-rifamos-styles{
    background-color:rgb(231, 230, 230);    
}
.slick-next::before {  
  font-size: 0px;
  color:#1266ab !important;
}
.slick-prev::before {  
  font-size: 0px;
  color:#1266ab !important;
}

.title {
  font-size: xx-large;    
}
.internalTitle{
  font-size: 25px;    
}
.card {
  margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}

.card-1 {
  padding: 10px 10px 10px 10px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

body{
  background: #e2e1e0;  
}

.bold{
  font-family: Helvetica;    
}

.card-text-main{
  font-size: medium;
  color: black;
}

.price{    
  font-size: 23px;
}
.card-title-text-center{
  padding-top: 10px;
}
.carousel{
  margin-bottom: 0rem;
}
.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -4em;
}
.slick-list { height: auto !important;}

.mat-stepper-vertical-line {
  border-top-width: 0px !important;
  margin: 0px 0px 0px -10px !important;
}
  
.mat-horizontal-stepper-header{
  pointer-events: none !important;
}

.mat-vertical-stepper-header{
  pointer-events: none !important;
}

.error-border{
  border: 2px solid red;
}
.error-color-font{
  color: red;
}
.error-message{
  font-weight: 50;
  text-align: center;
  font-size: 1.1em;
  color: red;
}

.menu-mobile+* .mat-menu-content {
  background-color: #1266ab;
  padding-top: 10px;
}


.menu-desktop+* .mat-menu-content {
  background-color: #1266ab;
  margin-top: 10px;
}