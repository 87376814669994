/* You can add global styles to this file, and also import other style files */

@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css'; 
@import '~swiper/swiper-bundle.min.css';
@import "assets/scss/base/base";
@import "assets/scss/admin/admin";
@import "assets/scss/theme/theme";
@import "assets/scss/gradients/gradients";
@import "assets/scss/rtl/rtl";


.p-t-20 {padding-top: 20px;}

.s-text8, .s-text8 a {
	font-family: Montserrat-Regular;
	font-size: 13px;
	color: #888888;
	line-height: 1.8;
}

.t-center {text-align: center;}


.p-t-15 {padding-top: 15px;}

.p-b-15 {padding-bottom: 15px;}

.super_container
{
	width: 100%;
	overflow: hidden;
}

.filter-row{
	background: #fff;
	padding: 8px 12px;
}

.card-title-mobile{
	font-size: 16px;
}