
@font-face {
  font-family: Montserrat-Regular;
  src: url('../fonts/montserrat/Montserrat-Regular.ttf'); 
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('../fonts/montserrat/Montserrat-Medium.ttf'); 
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/montserrat/Montserrat-Bold.ttf'); 
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Italic;
  src: url('../fonts/montserrat/Montserrat-Italic.ttf'); 
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Black;
  src: url('../fonts/montserrat/Montserrat-Black.ttf'); 
  font-display: swap;
}

@font-face {
  font-family: Linearicons;
  src: url('../fonts/Linearicons-Free-v1.0.0/WebFont/Linearicons-Free.ttf'); 
  font-display: swap;
}

/*[ RESTYLE TAG ]
///////////////////////////////////////////////////////////
*/
* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body, html {
	height: 100%;
	font-family: Montserrat-Regular, sans-serif;
  font-weight: 400;
  padding-bottom: 3rem;
}

/* ------------------------------------ */
a {
	font-family: Montserrat-Regular;
  font-weight: 400;
	font-size: 15px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
	color: #e65540;
}

/* ------------------------------------ */
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-family: Montserrat-Regular;
	font-size: 15px;
	line-height: 1.7;
	color: #888888;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/* ------------------------------------ */

/* textarea:focus, input:focus {
  border-color: transparent !important;
} */

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

/* ------------------------------------ */
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/* ------------------------------------ */
.container {
	max-width: 1200px;
}

.slick-slide {
  outline: none !important;
}
.container-margin
{
  margin-top: 3%;
  padding-bottom: 80px;
}

/*[ Slide2 ]
///////////////////////////////////////////////////////////
*/

/*[ Slick2 ]
-----------------------------------------------------------
*/
.wrap-slick2 {
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
}

/* ------------------------------------ */
.arrow-slick2 {
  position: absolute;
  z-index: 100;
  top: calc((100% - 70px) / 2);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 39px;
  color: #cccccc;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.arrow-slick2:hover {
  color: #666666;
}

.next-slick2 {
  right: -30px;
}

.prev-slick2 {
  left: -30px;
}

@media (max-width: 1280px) {
  .next-slick2 {
    right: 0px;
  }

  .prev-slick2 {
    left: 0px;
  }
}

@media (max-width: 1610px) {
  .rs1-slick2 .next-slick2 {
    right: 0px;
  }

  .rs1-slick2 .prev-slick2 {
    left: 0px;
  }
}


/*[ Block2 ]
///////////////////////////////////////////////////////////
*/
.block2-labelsale::before,
.block2-labelnew::before
{
  z-index: 100;
  font-family: Montserrat-Regular;
  font-size: 12px;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 22px;
  border-radius: 11px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.block2-labelsale::before {
  background-color: #e65540;
  content: 'Sale';
}

.block2-labelnew::before {
  background-color: #66a8a6;
  content: 'New';
}

/* ------------------------------------ */
.block2-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  opacity: 0;
}

/* ------------------------------------ */
.block2-btn-addcart {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -45px;
}

/* ------------------------------------ */
.block2-btn-towishlist,
.block2-btn-addwishlist {
  display: block;
  position: absolute;
  top: 26px;
  right: 20px;
  font-size: 20px;
  color: white;
  line-height: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.block2-btn-addwishlist:hover {
  color: white;
}

.block2-btn-addwishlist .icon-wishlist,
.block2-btn-towishlist .icon-wishlist {
  line-height: 0;
}

.block2-btn-addwishlist:hover .icon_heart_alt {
  display: none;
}

.block2-btn-addwishlist:hover .icon_heart {
  display: block;
}

/* ------------------------------------ */
.block2-btn-towishlist .icon_heart_alt {
  display: none;
}

.block2-btn-towishlist .icon_heart {
  display: block;
  color: #e65540;
}

/* ------------------------------------ */
.block2-overlay:hover {
  opacity: 1;
}

.block2-overlay:hover .block2-btn-addcart {
  bottom: 20px;
}

.block2-overlay:hover .block2-btn-addwishlist,
.block2-overlay:hover .block2-btn-towishlist{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}




